import "./App.css";
import { useState, useEffect } from "react";
//import Select, { SelectChangeEvent } from "@mui/material/Select";
//import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
//import FormControl from "@mui/material/FormControl";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SpeedIcon from "@mui/icons-material/Speed";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ListItemIcon from "@mui/material/ListItemIcon";

const firstSlide = 12;
const lastSlide = 37;
const defaultDelay = 100;

const Transitions = [
  { name: "Linear Fade", value: "linear" },
  { name: "Ease Fade", value: "ease" },
  { name: "Ease-in Fade", value: "ease-in" },
  { name: "Ease-out Fade", value: "ease-out" },
  { name: "Ease-in-out Fade", value: "ease-in-out" },
  { name: "Frames", value: undefined },
];

export default function Moon1() {
  const [slide, setSlide] = useState(firstSlide);
  const [delay, setDelay] = useState(defaultDelay);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [animation, setAnimation] = useState(5);
  const toggleAnimation = () =>
    setAnimation((a) => (a < Transitions.length - 1 ? a + 1 : 0));
  const transitionAnimation = Transitions[animation];
  const after = (s: number) => (s < lastSlide ? s + 1 : firstSlide);
  const slides = Array.from(Array(lastSlide - firstSlide).keys()).map(
    (s) => s + firstSlide
  );
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const setDelayAndClose = (d: number) => {
    setDelay(d);
    setAnchorEl(null);
  };
  useEffect(() => {
    const moveToNextSlide = () => {
      setSlide((s) => {
        console.log("Switching from slide ", s, " to ", after(s));
        return after(s);
      });
      const speedDiv = document.getElementById("currnet-speed") as any;
      setTimeout(moveToNextSlide, parseInt(speedDiv.value));
    };
    moveToNextSlide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="App">
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Button variant="contained" onClick={toggleAnimation}>
              {transitionAnimation.name}
            </Button>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              The Moon Cycle
            </Typography>
            <Typography>{`${86400 / delay} kx`}</Typography>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              title={`${86400 / delay} kx`}
            >
              <SpeedIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              //onClose={handleClose}
            >
              <MenuItem onClick={() => setDelayAndClose(10000)}>
                <ListItemIcon>
                  {delay === 10000 ? (
                    <RadioButtonCheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </ListItemIcon>
                8.64 kx
              </MenuItem>
              <MenuItem onClick={() => setDelayAndClose(5000)}>
                <ListItemIcon>
                  {delay === 5000 ? (
                    <RadioButtonCheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </ListItemIcon>
                17.28 kx
              </MenuItem>
              <MenuItem onClick={() => setDelayAndClose(2000)}>
                <ListItemIcon>
                  {delay === 2000 ? (
                    <RadioButtonCheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </ListItemIcon>
                43.2 kx
              </MenuItem>
              <MenuItem onClick={() => setDelayAndClose(1000)}>
                <ListItemIcon>
                  {delay === 1000 ? (
                    <RadioButtonCheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </ListItemIcon>
                86.4 kx
              </MenuItem>
              <MenuItem onClick={() => setDelayAndClose(500)}>
                <ListItemIcon>
                  {delay === 500 ? (
                    <RadioButtonCheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </ListItemIcon>
                172.8 kx
              </MenuItem>
              <MenuItem onClick={() => setDelayAndClose(200)}>
                <ListItemIcon>
                  {delay === 200 ? (
                    <RadioButtonCheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </ListItemIcon>
                432 kx
              </MenuItem>
              <MenuItem onClick={() => setDelayAndClose(100)}>
                {" "}
                <ListItemIcon>
                  {delay === 100 ? (
                    <RadioButtonCheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </ListItemIcon>
                864 kx
              </MenuItem>
              <MenuItem onClick={() => setDelayAndClose(50)}>
                {" "}
                <ListItemIcon>
                  {delay === 50 ? (
                    <RadioButtonCheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </ListItemIcon>
                1,728 kx
              </MenuItem>
              <MenuItem onClick={() => setDelayAndClose(20)}>
                {" "}
                <ListItemIcon>
                  {delay === 20 ? (
                    <RadioButtonCheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </ListItemIcon>
                4,320 kx
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <input type="hidden" id="currnet-speed" value={delay} />
        <header className="App-header">
          {slides.map((s) => (
            <div
              className="Slide"
              key={s}
              style={{
                backgroundImage: `url(/moon/${s}.png)`,
                opacity: s === slide ? 1 : 0,
                transition: transitionAnimation.value
                  ? `opacity ${delay}ms ${transitionAnimation.value}`
                  : undefined,
              }}
            ></div>
          ))}
        </header>
      </div>
    </>
  );
}
